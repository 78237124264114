import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    < SEO title="404: Not found" />
      <h1  style={{textAlign: 'center'}}>xX NOT FOUND Xx</h1> 
      <p  style={{textAlign: 'center'}}> I 'm sorry to say there is nothing here... sadface...</p>
      </>
)

export default NotFoundPage